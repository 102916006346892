import React from "react";
import { MainHeading } from "../components/MainHeading";
import { Page } from "../components/Page";

export default function ImprintPage() {
  return (
    <Page title="Impressum">
      <MainHeading>Impressum</MainHeading>
      <div className="prose">
        <p>Angaben gemäß §5 TMG Verantwortlich für den Inhalt</p>
        <p>
          <strong>Robert Maruhn</strong>
          <br />
          Essener Str. 109a
          <br />
          45529 Hattingen
        </p>
        <p>
          02324 / 43507
          <br />
          maromaruhn@t-online.de
        </p>
      </div>
    </Page>
  );
}
